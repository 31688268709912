const openDatabase = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("mediuk-email-db", 1);
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("emails")) {
        db.createObjectStore("emails", { keyPath: "id", autoIncrement: true });
      }
    };
    request.onsuccess = (event) => resolve(event.target.result);
    request.onerror = (event) => reject(event.target.error);
  });
};

export const saveEmailToDB = async (email) => {

    
  const db = await openDatabase();
  const transaction = db.transaction("emails", "readwrite");
  const store = transaction.objectStore("emails");
  store.add(email);

  console.log(transaction);
  return transaction.complete;
};

export const getEmailsFromDB = async () => {
  const db = await openDatabase();
  const transaction = db.transaction("emails", "readonly");
  const store = transaction.objectStore("emails");
  return new Promise((resolve, reject) => {
    const request = store.getAll();
    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};

export const clearEmailsFromDB = async () => {
  const db = await openDatabase();
  const transaction = db.transaction("emails", "readwrite");
  const store = transaction.objectStore("emails");
  store.clear();
  return transaction.complete;
};